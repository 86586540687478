.cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    padding: 10pt;
    z-index: 1000;
  }
  
  .cookie-consent p {
    margin: 0;
  }
  
  .link-button, .accept-button {
    background: none;
    border: none;
    color: #0a74da;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1em;
    margin-left: 10px;
  }
  