.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .modal-content {
    padding: 20px;
  }
  
  .accept-button {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    background-color: #0a74da;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1em;
  }
  