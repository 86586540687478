@media (max-width: 600px) {
  html{width: 100vh;}
}

/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  overflow-x: hidden;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

header {
  background: #0e1f29;
  color: #eee;
  padding: 1em 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
header img
{   
  width: 200pt;
  margin-left: 20pt;
}
header nav{
  flex: 1;
}

header nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

header nav ul li {
  margin: 0 1em;
}

header nav ul li:hover {
  text-decoration: underline #eee;
}

header nav ul li a {
  color: #eee;
  text-decoration: none;
  cursor: pointer;
}

footer{
  background: #0e1f29;
}

footer p{
  margin: 0 !important;
  padding: 20pt;
  color: #eee;
}

section {
  position: relative;
  height: 100vh;
}

.color-blue-mark{
  color: #002e43;
}



