/* .ccard-item{ 
    padding: 10pt;
    background: #ddd;
    min-width: 100pt;
    margin-bottom: 10pt;
    cursor: pointer;
    border-radius: 5pt;
    box-shadow: 0 0 0.3em #333;
}

.ccard-item:hover{ 
    transform: scale(1.05);    
    transition: transform ease 0.5s;
} */

.card-item {
    position: relative;
    margin: 10pt;
    padding: 10pt;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5pt;
    text-align: center;
    transition: transform 0.3s;
    min-width: 130pt;
    height: 110pt;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
.card-area .left:hover {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.card-area .right:hover {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
 
  .card-area:nth-child(1) .card-item:hover {
    transform: scale(1.05);
    background: #002e43;
    color: #eee;
  }
  .card-area:nth-child(2) .card-item:hover {
    transform: scale(1.05);
    background: #03425f;
    color: #eee;

  }
  .card-area:nth-child(3) .card-item:hover {
    transform: scale(1.05);
    background: #239dc3;
    color: #eee;
  }
  .card-area:nth-child(4) .card-item:hover {
    transform: scale(1.05);
    background: #666;
    color: #eee;
  }
  .card-area:nth-child(5) .card-item:hover {
    transform: scale(1.05);
    background: #9a9a9a;
    color: #eee;
  }
  .card-area:nth-child(6) .card-item:hover {
    transform: scale(1.05);
    background: #002e43;
    color: #eee;
  }
  
  
  
  .card-item .details {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 170pt;
    height: 130px;
    background: #002e43;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: transform 0.3s;
    opacity: 0;
    pointer-events: none;
    border-radius: 5pt;  
    
  }

  .card-item.left  .details {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.card-item.right  .details {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
  
  .card-item.left .details {
    left: calc(100% + 10px); /* Position the details div to the right */
  }
  
  .card-item.right .details {
    right: calc(100% + 10px); /* Position the details div to the left */
  }
  
  .card-item:hover .details {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(-50%) translateX(0);
    text-align: justify;
    padding: 10pt;
    box-sizing: border-box;
  }

.get-started-button{
    padding: 10pt 50pt;
    border: none;
    background: #002e43;
    color: #FFF;
    text-transform: UPPERCASE;
    font-size: 14pt;
    border-radius: 2pt;
    cursor: pointer;
    box-shadow: 0 0 0.6em #333;

}
.get-started-button:hover{
    transform: scale(1.05);
    transition: all ease 0.3s;
    background: #03425f;

}
.presentation-text{
    text-align: justify;
    padding-left: 10pt;
    letter-spacing: 0.5px;
    word-spacing: 1px;
}
.secao-categoria{
    flex-direction: column;
    display: 'flex';
    flex-wrap: wrap;
    width: 100%;
    position: absolute;
    top: 0;
}

.secao-categoria h1 {
    font-size: 30pt;
    margin-top: 50pt;
}
#section1{
    background-image: url("/public/images/fundo-home.png") !important;
    background-position: bottom !important;
    background-size: cover !important;
    /* background-blend-mode: screen;     */
    color: #eee;
  }
  
  .login-button{
    padding: 5pt 15pt;
    border: none;
    background: #239dc3;
    color: #eee;
    text-transform: UPPERCASE;
    font-size: 14pt;
    border-radius: 2pt;
    cursor: pointer;
    /* box-shadow: 0 0 0.6em #333; */
    margin-right: 20pt;
    display: flex;
    border: 1px solid #eee;
  
  }
  .login-button:hover{
    transform: scale(1.01);
    transition: all ease 0.3s;
    background: #03425f;
  
  }
  
  .login-button svg{ margin-left: 10pt;}  
  
  #section2{
    background-image: url("/public/images/fundo-categorias.png") !important;
    background-position: bottom !important;
    background-size: cover !important;
    /* background-blend-mode: screen;     */
    color: #666;
    position: relative;
  }
  
  
  #section3{
    background-image: url("/public/images/fundo-parceiros.png") !important;
    background-position: bottom !important;
    background-size: cover !important;
    /* background-blend-mode: screen;     */
  }

.section3 {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  
  .pillar {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin: 10px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .pillar::before,
  .pillar::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 20px;
    background: #ccc;
    border-radius: 10px;
  }
  
  .pillar::before {
    top: -20px;
  }
  
  .pillar::after {
    bottom: -20px;
  }
  
  .pillar button{
    padding: 5pt 15pt;
    border: none;
    background: #239dc3;
    color: #eee;
    text-transform: UPPERCASE;
    font-size: 14pt;
    border-radius: 2pt;
    cursor: pointer;
    /* box-shadow: 0 0 0.6em #333; */
    margin-right: 20pt;
    display: flex;
    border: 1px solid #eee;
  }
  .pillar button:hover{
    transform: scale(1.01);
    transition: all ease 0.3s;
    background: #03425f;
  
  }



@media (max-width: 600px) {
  header{ display: flex; justify-content: space-between !important;}

  nav{display: none;}
 

  .secao-apresentacao{
    display: flex;
    flex-direction: column;
  }
  .section3{
    flex-direction: column;      
  }
  .pillar{
    margin-bottom: 40pt;
  }
  .details{display: none !important;}
  
  #section1{margin-top: 70pt !important; padding: 10pt !important;}
  #section2{height: 100vh !important;}
  #section3{height: 130vh !important;}



  #section3{padding: 0 20pt !important; margin-top: 0 !important;}
  .login-button span {display: none !important;}
  .login-button { 
    border-radius: 50% !important; 
    width: 50pt; 
    height: 50pt; 
    font-size: 20pt; 
    padding: 0 !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
}
  
  .login-button svg {
    margin: 0 !important;
  }  

}